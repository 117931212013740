import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, ToggleButton } from 'react-bootstrap';
import DateRange from '../DateRange';
import MultiselectBox from '../Utilities/MultiselectBox';
import { fetchDataAuthenticated } from '../Utilities/HttpUtils';
import useAlert from '../Alerts/useAlert';
import UserContext from '../Contexts/UserContext';
import { getLotListFilters, persistLotListFilters } from "../Utilities/SessionStorage";
import LotListTableSelector from './LotListTableSelector';

function LotList({ status, tableName }) {
    const { t } = useTranslation();

    status ??= "";

    const defaultDateRange = {
        from: null, //new Date().toISOString().split('T')[0],
        to: null
    };

    const { setAlert } = useAlert();
    const userContext = useContext(UserContext);

    const [businessUnits, setBusinessUnits] = useState();
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);

    const [lines, setLines] = useState();
    const [selectedLines, setSelectedLines] = useState([]);
    const [dateFilter, setDateFilter] = useState(defaultDateRange);
    const [includeGraded, setIncludeGraded] = useState(false);

    useEffect(() => {
        let filterItems = getLotListFilters();
        if (filterItems) {

            // If we have a session value override the state value
            if (filterItems.includeGraded !== undefined) {
                setIncludeGraded(filterItems.includeGraded);
            }
        }
    }, [includeGraded]);

    useEffect(() => {
        getBusinessUnits();
        if (businessUnits) {
            getLines();
        }
    }, [selectedBusinessUnits]);

    useEffect(() => {
        if (userContext.userName && userContext.userName !== "")
            getUserPreferences();

    }, [userContext, userContext.userName]);

    async function getBusinessUnits() {
        fetchDataAuthenticated("api/v1/businessunit")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get business units");
            })
            .then(data => {
                setBusinessUnits(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    async function getLines() {
        let businessUnitIds = [];

        if (selectedBusinessUnits && selectedBusinessUnits.length > 0) {
            businessUnitIds = businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)).map(bu => bu.businessUnitId);
        } else {
            businessUnitIds = businessUnits.map(bu => bu.businessUnitId);
        }

        fetchDataAuthenticated(`api/v1/line/multiplebusinessunit?businessUnitIds=${businessUnitIds}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get lines");
            })
            .then(data => {
                setLines(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    async function getUserPreferences() {

        await fetchDataAuthenticated("api/v1/userpreferences?username=" + userContext.userName)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error(await response.json());
            })
            .then(data => {
                setSelectedBusinessUnits(data.defaultBusinessUnitCodes);
                setSelectedLines(data.defaultLines);
            })
            .catch(error => {
                setAlert("danger", "Error", "Failed to get user preferences. " + error.message);
            })
    }

    const handleIncludeGraded = (e) => {
        let includeGradedValue = e.target.checked;

        persistLotListFilters('includeGraded', includeGradedValue);

        setIncludeGraded(includeGradedValue);
    }

    return (
        <Container fluid>
            <Row className="mb-3 gy-2 d-flex align-items-bottom">
                <Col md>
                    <DateRange dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Col>
                <Col md>
                    <MultiselectBox
                        name={t("LotList.Filters.Business")}
                        options={businessUnits}
                        selectedOptions={businessUnits && selectedBusinessUnits && selectedBusinessUnits.length > 0 ? businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)) : []}
                        setSelectedOptions={setSelectedBusinessUnits}
                        map="code" />
                </Col>
                <Col md>
                    <MultiselectBox
                        name={t("LotList.Filters.Line")}
                        options={lines}
                        selectedOptions={lines && selectedLines && selectedLines.length > 0 ? lines.filter(l => selectedLines.includes(l.productionLine)) : []}
                        setSelectedOptions={setSelectedLines}
                        map="productionLine" />
                </Col>
                <Col md={2}>
                    <div className="d-flex justify-content-end">
                        <ToggleButton
                            id="includeGraded"
                            type="checkbox"
                            variant="outline-primary"
                            checked={includeGraded}
                            value={includeGraded}
                            onChange={handleIncludeGraded}
                        >
                            {t("LotList.Filters.IncludeGraded")}
                        </ToggleButton>
                    </div>
                </Col>
            </Row>

            <LotListTableSelector tableName={tableName} tableFilters={{ selectedBusinessUnits: selectedBusinessUnits, selectedLines: selectedLines, status: status, dateFilter: dateFilter, includeGraded: includeGraded }} />

        </Container >
    );
}

export default LotList;