import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { fetchDataAuthenticated } from '../../../Utilities/HttpUtils';
import { DateToLocaleString, longDateAndTimeFormat } from '../../../Utilities/DateUtils';
import CommentDialog from '../../../Dialogs/CommentDialog';
import CommentsTable from './CommentsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import useAlert from '../../../Alerts/useAlert';
import ReasonFilter from './CommentsReasonFilter';

function GradingCommentsSection({ gradingComments, lotNumber, getLotDetail, currentRevision }) {

    const getRevision = (lotRevisionId) => {
        getLotDetail(lotRevisionId);
    }

    const tableColumns = [
        {
            Header: 'Date',
            accessor: 'commentDate',
            Cell: (props) => {
                return DateToLocaleString(props.value, longDateAndTimeFormat);
            }
        },
        {
            Header: 'Account',
            accessor: 'account'
        },
        {
            Header: 'Reason',
            accessor: 'reason',
            Filter: ReasonFilter,
            filter: "jsonArray"
        },
        {
            Header: 'Comment',
            accessor: 'commentText',
            Cell: (props) => {
                let comment = props.row.original;

                return (
                    <div className="text-break">
                        {comment.userComment &&
                            <p style={{ whiteSpace: 'pre-wrap' }}>User Comment: {comment.userComment}</p>
                        }
                        {comment.systemComment &&
                            <p style={{ whiteSpace: 'pre-wrap' }}>System Comment: {comment.systemComment}</p>
                        }
                    </div>
                );
            }
        },
        {
            accessor: 'lotRevisionId',
            Cell: (props) => {

                let title = `Revision ${DateToLocaleString(props.row.original.commentDate, longDateAndTimeFormat)}`;

                if (props.row.original.lotRevisionId !== currentRevision) {
                    return (
                        <FontAwesomeIcon
                            icon={faClock}
                            className="link"
                            title={title}
                            onClick={() => getRevision(props.row.original.lotRevisionId)} />
                    );
                } else {
                    return "";
                }
            }
        },
        {
            accessor: 'userComment'
        },
        {
            accessor: 'systemComment'
        }
    ];

    const initialState = {
        sortBy: [
            {
                id: 'commentDate',
                desc: true
            }
        ],
        hiddenColumns: ["userComment", "systemComment"]
    };

    return gradingComments?.length > 0
        ? <CommentsTable columns={tableColumns} data={gradingComments} initialState={initialState} addGradingCommentButton={AddGradingComment(lotNumber, getLotDetail)} />
        : <div className="d-flex justify-content-between">
            <p>None</p>
            {AddGradingComment(lotNumber, getLotDetail)}
        </div>;
}

function AddGradingComment(lotNumber, getLotDetail) {

    const { t } = useTranslation();
    const { setAlert } = useAlert();

    const [showGradingCommentDialog, setShowGradingCommentDialog] = useState(false);

    const handleGradingCommentSave = (comment) => {
        fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/gradingComment`, 'PUT', JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    getLotDetail();
                    setShowGradingCommentDialog(false);
                } else {
                    throw new Error("Failed to add the comment to the lot");
                }
            })
            .catch(error => {
                setShowGradingCommentDialog(false);
                setAlert("danger", "Error", error.message);
            });
    }

    return (
        <>
            <Button variant="primary" onClick={() => setShowGradingCommentDialog(true)}>
                {t('LotDetails.Summary.AddGradingComment')}
            </Button>
            <CommentDialog showDialog={showGradingCommentDialog} closeDialog={() => setShowGradingCommentDialog(false)} onSave={handleGradingCommentSave} />
        </>
    );
}

export default GradingCommentsSection;