import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable from '../../DataTable/datatable.component';
import { Link } from 'react-router-dom';
import DateColumn from '../../DataTable/datatable.datecolumn';
import { fetchDataAuthenticated, correlationId } from '../../Utilities/HttpUtils';
import { getTableFilters, getSortOrder } from '../../Utilities/SessionStorage';
import useAlert from '../../Alerts/useAlert';
import SelectFilter from '../../DataTable/datatable.selectfilter';

function ReportingQirListTable({ tableFilters }) {

    const { t } = useTranslation();
    const { setAlert } = useAlert()

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getLots();
    }, [tableFilters.dateFilter, tableFilters.selectedBusinessUnits, tableFilters.selectedLines]);

    async function getLots() {
        let params = new URLSearchParams();

        if (tableFilters.dateFilter?.from) {
            params.append("dateFrom", tableFilters.dateFilter.from);
        }

        if (tableFilters.dateFilter?.to) {
            params.append("dateTo", tableFilters.dateFilter.to);
        }

        if (tableFilters.selectedBusinessUnits.length > 0) {
            params.append("businessUnits", tableFilters.selectedBusinessUnits);
        }

        if (tableFilters.selectedLines.length > 0) {
            params.append("lines", tableFilters.selectedLines);
        }

        await fetchDataAuthenticated("api/v1/lots/WithQirsOnly?" + params.toString())
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error(`Failed to get lots [${correlationId}]`)
            })
            .then(data => {
                setData(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = [
        {
            Header: t('QirList.ColHead.BusinessUnit'),
            accessor: 'businessUnit',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('QirList.ColHead.LotNumber'),
            accessor: 'lotNumber',
            Cell: (props) => {
                return <Link to={{ pathname: `/lotdetails/${props.value}` }}>{props.value}</Link>
            },
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.QirIncidentId'),
            accessor: 'qirIncidentId',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.QirStatement'),
            accessor: 'qirStatement',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.QirReportedBy'),
            accessor: 'qirReportedBy',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.OriginalGrade'),
            accessor: 'originalGrade',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.Grade'),
            accessor: 'grade',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.Weight'),
            accessor: 'lotWeight',
            filter: 'NoFilter'
        },
        {
            Header: t('LotList.ColHead.LoadoutWeight'),
            accessor: 'loadoutWeight',
            filter: 'NoFilter'
        },
        DateColumn({ header: t('QirList.ColHead.QirReportedDate'), accessor: 'qirReportedDate' }),
        DateColumn({ header: t('QirList.ColHead.QirIncidentDate'), accessor: 'qirIncidentDate' }),
        DateColumn({ header: t('QirList.ColHead.QirEndDate'), accessor: 'qirEndDate' }),
        {
            Header: t('QirList.ColHead.LastModifiedBy'),
            accessor: 'lastModifiedBy',
            filter: 'includesString'
        },
        {
            Header: t('QirList.ColHead.LastComment'),
            accessor: 'lastGradingComment',
            filter: 'includesString'
        },
        DateColumn({ header: t('QirList.ColHead.LastGradedUTC'), accessor: 'lastGradedUTC' }),
        {
            Header: t('QirList.ColHead.LastGradedReasonCode'),
            accessor: 'lastGradedReasonCode',
            filter: 'includesString'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    const initialState = {
        pageSize: 20
    }

    let sortOrderItems = getSortOrder();
    if (sortOrderItems) {

        sortOrderItems.sort((a, b) => (a.sortedIndex > b.sortedIndex) ? 1 : -1)

        initialState.sortBy = [];

        for (let i = 0; i < sortOrderItems.length; i++) {

            let sortOrderItem = sortOrderItems[i];
            if (sortOrderItem && sortOrderItem.isSorted) {
                initialState.sortBy.push({
                    id: sortOrderItem.id,
                    desc: sortOrderItem.isSortedDesc
                });
            }
        }
    } else {
        initialState.sortBy = [
            {
                id: 'buildStart',
                desc: true
            }
        ];
    }

    let filterItems = getTableFilters();
    if (filterItems) {

        initialState.filters = [];

        for (let i = 0; i < filterItems.length; i++) {

            let filterItem = filterItems[i];
            initialState.filters.push({
                id: filterItem.id,
                value: filterItem.value
            });
        }
    }

    return data.length > 0
        ? <DataTable tableColumns={columns} tableData={data} initialState={initialState} />
        : <h4 className="d-flex justify-content-center mt-5">No QIRs to show</h4>
}

export default ReportingQirListTable;