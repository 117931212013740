import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

export function SectionNavigation() {
    return (
        <Row className="gy-2 mb-2">
            <Col sm xs={3}>
                <HashLink to="#warnings-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        Warnings
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#gradingcomments-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        LBM Comments
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#comments-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        L3 Comments
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#qualities-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        Qualities
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#labresults-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        Lab Results
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#genealogy-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        Genealogy
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#qirs-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        QIRs
                    </Badge>
                </HashLink>
            </Col>
        </Row>
    );
}
