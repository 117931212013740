import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import { DraggableModalDialog } from '../../Dialogs/DraggableModalDialog';
import LotSplitByDateTimeBuilder from './LotSplitByDateTimeBuilder';
import LotSplitBySiloBuilder from './LotSplitBySiloBuilder';
import LotSplitByBatchBuilder from './LotSplitByBatchBuilder';
import LotSplitResult from './LotSplitResult';
import CommentDialog from '../../Dialogs/CommentDialog';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import useAlert from '../../Alerts/useAlert';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function LotSplitDialog({ showDialog, closeDialog, lotNumber, buildStart, buildEnd, getLotDetail, currentGrade, alternativeGrades, allGrades, loadingAlternativeGrades, loadingAllGrades, unreliableBaggingData }) {

    const { t } = useTranslation();

    const getFormName = (splitType) => {
        switch (splitType) {
            case "time":
                return "lotSplitByDateTimeBuilderForm";
            case "silo":
                return "lotSplitBySiloBuilderForm";
            case "batch":
                return "lotSplitByBatchBuilderForm";
            default:
                console.log("Unexpect split type '"+splitType+"' when building form name")
                return null;
        }
    }

    const initialRows = [{
        startTime: moment(new Date(buildStart)).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(new Date(buildStart)).format('YYYY-MM-DDTHH:mm'),
        suffix: 'B',
        grade: ''
    }];

    const initialLotSplitType = "time";
    const initialFormName = getFormName(initialLotSplitType);

    const initialSilos = [];
    const initialBatches = [];

    const [lotSplitType, setLotSplitType] = useState(initialLotSplitType);
    const [builderFormName, setBuilderFormName] = useState(initialFormName);
    const [lotSplitRows, setLotSplitRows] = useState(initialRows);
    const [lotSplitSilos, setLotSplitSilos] = useState(initialSilos);
    const [lotSplitBatches, setLotSplitBatches] = useState(initialBatches);

    const [lotSplitResult, setLotSplitResult] = useState();
    const [formValidated, setFormValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [disableConfirmSplit, setDisableConfirmSplit] = useState(true);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingReason, setLoadingReason] = useState("");

    const { setAlert } = useAlert();

    useEffect(() => {
        if (loadingAlternativeGrades || loadingAllGrades) {
            setLoading(true);
            setLoadingReason(t("LotSplit.LoadingGrades"));
        } else {
            setLoading(false);
        }
    }, [loadingAlternativeGrades, loadingAllGrades]);

    const updatedSplit = () => {
        setDisableConfirmSplit(true);
    }

    // Validate the form and perform required action
    const handleSubmit = (e) => {
        let validSplit = validateSplit(e);

        if (validSplit) {
            let buttonClicked = e.nativeEvent.submitter.name;

            if (buttonClicked === "checkSplit") {
                checkLotSplit();
            }

            if (buttonClicked === "confirmSplit") {
                setShowCommentDialog(true);
            }
        } else {
            setLotSplitResult(undefined);
        }
    };

    // Validate the form
    const validateSplit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let validSplit = false;

        const form = e.currentTarget;

        if (form.checkValidity() === true) {

            if (lotSplitType === "time") {
                // find any split dates that fall outside of the lot build date range
                let invalidDates = lotSplitRows.find((e) => {
                    let startTimePlusOneMinute = moment(e.startTime).add(1, 'minutes').format('YYYY-MM-DDTHH:mm');
                    return startTimePlusOneMinute < buildStart || e.endTime > buildEnd;
                });

                if (invalidDates) {
                    setFormErrors({ ...formErrors, splitTime: t("LotSplit.InvalidDates") });
                    console.warn("Split times cannot fall outside of the lot build date range");
                } else {
                    setFormErrors({});
                    validSplit = true;
                }
            } else {
                // Validate other split types..

                validSplit = true;
            }
        } else {
            console.warn("Lot split form was invalid");
        }

        setFormValidated(true);
        return validSplit;
    }

    // Check the lot split and return bag numbers and weights
    const checkLotSplit = async () => {
        setLoading(true);
        setLoadingReason("Checking split");
        setDisableConfirmSplit(true);

        let body = {
            splitType: lotSplitType,
            lotSplitByTimeSections: lotSplitRows,
            lotSplitBySiloSections: lotSplitSilos,
            lotSplitByBatchSections: lotSplitBatches,
            comment: ""
        };

        await fetchDataAuthenticated(`api/v1/lot/${lotNumber}/split/check`, "POST", JSON.stringify(body))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    let errorMessage = await response.text();
                    throw new Error(`${t("LotSplit.Failed")} ${errorMessage}`);
                }
            })
            .then(response => {
                setLotSplitResult(response);
                setDisableConfirmSplit(false);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    // Perform the lot split
    const splitLotWithComment = async (comment) => {

        setLoading(true);
        setLoadingReason("Splitting Lot");
        setDisableConfirmSplit(true);

        let body = {
            splitType: lotSplitType,
            lotSplitByTimeSections: lotSplitRows,
            lotSplitBySiloSections: lotSplitSilos,
            lotSplitByBatchSections: lotSplitBatches,
            comment: comment
        };

        await fetchDataAuthenticated(`api/v1/lot/${lotNumber}/split`, "POST", JSON.stringify(body))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", t("LotSplit.Successful"));
                    getLotDetail();
                    closeDialogAndResetForm();
                } else {
                    let errorMessage = await response.text();
                    throw new Error(`${t("LotSplit.Failed")} ${errorMessage}`);
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                setDisableConfirmSplit(false);
                setLoading(false);
            });
    }

    const closeDialogAndResetForm = () => {
        setLotSplitRows(initialRows);
        setLotSplitResult(null);
        setFormValidated(false);
        setDisableConfirmSplit(true);
        setLoading(false);
        setLoadingReason("");
        closeDialog();
    }

    const handleSplitTypeChange = (splitTypeVal) => {
        setLotSplitType(splitTypeVal);
        setBuilderFormName(getFormName(splitTypeVal))
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("LotSplit.Title")} {lotNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={loading ? "fade-content" : ""}>
                        <Tabs className="mb-3" defaultActiveKey={lotSplitType} onSelect={handleSplitTypeChange}>
                            <Tab eventKey="time" title={t("LotSplit.ByTime")}>
                                <Form id="lotSplitByDateTimeBuilderForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                                    <LotSplitByDateTimeBuilder lotSplitRows={lotSplitRows} setLotSplitRows={setLotSplitRows} updatedSplit={updatedSplit} currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} />
                                </Form>
                            </Tab>
                            <Tab eventKey="silo" title={t("LotSplit.BySilo")}>
                                <Form id="lotSplitBySiloBuilderForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                                    <LotSplitBySiloBuilder lotSplitSilos={lotSplitSilos} setLotSplitSilos={setLotSplitSilos} updatedSplit={updatedSplit} lotNumber={lotNumber} currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} />
                                </Form>
                            </Tab>
                            <Tab eventKey="batch" title={t("LotSplit.ByBatch")}>
                                <Form id="lotSplitByBatchBuilderForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                                    <LotSplitByBatchBuilder lotSplitBatches={lotSplitBatches} setLotSplitBatches={setLotSplitBatches} updatedSplit={updatedSplit} lotNumber={lotNumber} currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} />
                                </Form>
                            </Tab>
                        </Tabs>
                        {formErrors.splitTime &&
                            <p className="text-danger mt-3">{formErrors.splitTime}</p>
                        }
                        {lotSplitResult && lotSplitResult.details.length > 0 &&
                            <LotSplitResult result={lotSplitResult} />
                        }
                        {lotSplitResult && lotSplitResult.details.length === 0 &&
                            <p className="text-danger mt-3">{t("LotSplit.NoResults")}</p>
                        }
                        {unreliableBaggingData &&
                            <div className="d-flex justify-content-center">
                            <p className="text-danger"><FontAwesomeIcon icon={faTriangleExclamation} className="me-1 mt-3" /> {t("LotSplit.UnreliableBaggingData")}</p>
                            </div>
                        }
                        {lotSplitResult && lotSplitResult.warnings.length > 0 &&
                                <div className="d-flex justify-content-center">
                                {
                                    lotSplitResult.warnings.map((l, i) => {
                                        return (
                                            <p className="text-danger"><FontAwesomeIcon icon={faTriangleExclamation} className="me-1 mt-3" /> {l}</p>
                                        );
                                    })
                                }
                            </div>
                        }
                </Modal.Body>
                <Modal.Footer>
                    {loading &&
                        <div className="d-flex align-items-start me-auto">
                            <Spinner animation="border" size="md" className="me-3" />
                            <p className="mt-2">{loadingReason}</p>
                        </div>
                    }
                    <Button variant="secondary" onClick={closeDialogAndResetForm}>
                        {t("LotSplit.Close")}
                    </Button>
                    <Button variant="primary" type="submit" form={builderFormName} name="confirmSplit" disabled={disableConfirmSplit}>
                        {t("LotSplit.Confirm")}
                    </Button>
                    <Button variant="primary" type="submit" form={builderFormName} name="checkSplit">
                        {t("LotSplit.Check")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={splitLotWithComment} />
        </>
    );
}

export default LotSplitDialog;