import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogIntegerInput, DialogCheckbox } from '../../DialogInputs';
import { useTranslation } from 'react-i18next';
function FunctionParamsDialog({ fieldName, func, showDialog, closeDialog, parameters, setParameters, save }) {
    const { t } = useTranslation();
    const [paramFieldErrors, setParamFieldErrors] = useState({});

    const InputName = (parameter) => {
        return fieldName + "_" + func.name + "_" + parameter.name;
    };

    const handleParameterInputChange = (e, parameterIndex, inputName, parameters, setParameters, paramFieldErrors, setParamFieldErrors) => {
        let value = e.currentTarget.value;

        if (e.currentTarget.type === "checkbox")
            value = e.currentTarget.checked;

        e.currentTarget.setCustomValidity("");

        setParameters({
            ...parameters,
            [parameterIndex]: value
        });

        if (e.currentTarget.validity.valid) {
            setParamFieldErrors({
                ...paramFieldErrors,
                [inputName]: null
            });
        }
        else {
            setParamFieldErrors({
                ...paramFieldErrors,
                [inputName]: e.currentTarget.validationMessage
            });
        }
    };

    const onChangeParameter = (parameterIndex, inputName) => (e) => {
        handleParameterInputChange(e, parameterIndex, inputName, parameters, setParameters, paramFieldErrors, setParamFieldErrors);
    };

    return (
        <CommonConfigDialog title="Function Parameters" dialogData={parameters} showDialog={showDialog} closeDialog={closeDialog} save={save} fieldErrors={paramFieldErrors} setFieldErrors={setParamFieldErrors} wideDialog={false} formId="paramsConfigForm" requireComment={false}>
            {func.parameters.map((parameter, index) => {
                let inputName = InputName(parameter);

                if (parameter.type === "Int32") {
                    return <RequiredDialogIntegerInput key={inputName} title={t("Configuration.QualityEdit.FunctionParams." + parameter.name)} name={inputName} value={parameters[index]} error={paramFieldErrors[inputName]} onChange={onChangeParameter(index, inputName)} required={true} />;
                }

                if (parameter.type === "Boolean") {
                    // Need to allow for the param coming in is the string "true"
                    var isTrue = (parameters[index] === undefined || parameters[index] === null) ? (false) : (String(parameters[index]).toLowerCase() === 'true');
                    return <DialogCheckbox key={inputName} title={t("Configuration.QualityEdit.FunctionParams." + parameter.name)} name={inputName} checked={isTrue} error={paramFieldErrors[inputName]} onChange={onChangeParameter(index, inputName)} />;
                }

                return <DialogInput key={inputName} title={t("Configuration.QualityEdit.FunctionParams."+parameter.name)} name={inputName} value={parameters[index]} error={paramFieldErrors[inputName]} onChange={onChangeParameter(index, inputName)}/>;
            })}
        </CommonConfigDialog>
    );
}

export default FunctionParamsDialog;