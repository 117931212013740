import React from 'react';

import './LotSplitBar.css';

function LotSplitResult({ result }) {

    const colours = ["#FF595E", "#FFCA3A", "#1982C4", "#6A4C93"];
    const suffixSet = Array.from(new Set(result.details.map(a => a.suffix)));

    const generateRandomColour = () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }

    // Generate a colour dictionary of lot suffix to colour (B:red) for the above colours. Use random colour if index out of bounds.
    const colourDict = Object.fromEntries(suffixSet.filter(x => x !== "A").map((suffix, index) => [suffix, colours[index] || generateRandomColour()]));
    // Always ensure that A is green
    colourDict.A = "#8AC926";

    // Work out if the row is the first for its suffix. Used to have lot weight span multiple rows.
    const isFirstRowForLotSuffix = (l, i) => {
        let index = result.details.findIndex(x => x.suffix === l.suffix)
        return index === i;
    }

    return (
        <>
            <div className="lot-split-bar mt-3 mb-3">
                {
                    result.lotSplitSegments.map((l, i) => {
                        return (
                            <div key={`lot-split-fragment-${i}`} className="bar-data" style={{ background: `${colourDict[l.suffix]}`, width: `${l.percent}%` }}>
                                <div className="lot-split-text">{l.suffix}</div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="lot-split-details">
                <table>
                    <thead>
                        <tr>
                            <th>Lot</th>
                            <th>Grade</th>
                            <th>Weight</th>
                            <th>Silo Key</th>
                            <th>Line</th>
                            <th>Bags</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            result.details.map((l, i) => {
                                return (
                                    <tr key={`lot-split-row-${i}`}>
                                        {isFirstRowForLotSuffix(l, i) &&
                                            <>
                                                <td rowSpan={result.details.filter(x => x.suffix === l.suffix).length} valign="top">{l.lotNumber}</td>
                                                <td rowSpan={result.details.filter(x => x.suffix === l.suffix).length} valign="top">{l.grade}</td>
                                                <td rowSpan={result.details.filter(x => x.suffix === l.suffix).length} valign="top">{l.weight}</td>
                                            </>
                                        }
                                        <td>{l.siloKey}</td>
                                        <td>{l.equipment}</td>
                                        <td>{l.bagNumbers}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default LotSplitResult;