import React, { useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { DraggableModalDialog } from '../Dialogs/DraggableModalDialog';
import CommentDialog from '../Dialogs/CommentDialog';
import { fetchDataAuthenticated } from '../Utilities/HttpUtils';
import useAlert from '../Alerts/useAlert';

function RegradeDialog({ showDialog, closeDialog, lotNumber, getLotDetail, alternativeGrades, grades, loading }) {

    const [selectedGrade, setSelectedGrade] = useState(null);
    const [selectedOther, setSelectedOther] = useState(false);
    const [formValidated, setFormValidated] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const { setAlert } = useAlert();

    const handleSelectGrade = (e) => {

        if (e.target.dataset.id !== "other") {
            setSelectedOther(false);
            setSelectedGrade(e.target.dataset.id);
        } else {
            setSelectedOther(true);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        } else {
            console.warn("Change lot grade form was invalid");
        }

        setFormValidated(true);
    };

    const regradeLotWithComment = async (comment) => {

        let body = {
            grade: selectedGrade,
            comment: comment
        };

        fetchDataAuthenticated(`api/v1/lot/grade/${lotNumber}`, "PUT", JSON.stringify(body))
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Success", "Lot was regraded successfully");
                    getLotDetail();
                    closeDialogAndResetForm();
                } else {
                    throw new Error("Failed to regrade lot.");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            });
    }

    const closeDialogAndResetForm = () => {
        setFormValidated(false);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static">
                <Modal.Header closeButton>
                    {loading &&
                        <Modal.Title>Loading alternative grades</Modal.Title>
                    }
                    {!loading &&
                        <Modal.Title>Select alternative grade</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {loading &&
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    }
                    {!loading &&
                        <Form id="regradeLot" noValidate validated={formValidated} onSubmit={handleSave}>
                            <Form.Group controlId="gradeSelection" className="mb-3">
                                {
                                    alternativeGrades.map((grade, index) => {
                                        let colourCodeGrade = grade.lotOnSpecForGrade ? { color: "black" } : { color: "red" };
                                        return (
                                            <Form.Check
                                                type="radio"
                                                name="grades"
                                                key={index}
                                                id={`grade-${grade.grade}`}
                                                data-id={grade.grade}
                                                label={grade.grade}
                                                className="form-radio-input ms-2 mb-3"
                                                style={colourCodeGrade}
                                                onChange={handleSelectGrade}
                                                required
                                            />
                                        );
                                    })
                                }
                                <Form.Check
                                    type="radio"
                                    key={999}
                                    name="grades"
                                    id="grade-other"
                                    data-id="other"
                                    label="Other"
                                    className="form-radio-input ms-2 mb-3"
                                    onChange={handleSelectGrade}
                                    required
                                />
                                {selectedOther &&
                                    <Form.Group className="mb-3" controlId="selectOtherGrade">
                                        <Form.Select
                                            onChange={(e) => setSelectedGrade(e.target.value)}>
                                            <option>Select Grade</option>
                                            {
                                                grades.map((grade) => {
                                                    return <option value={grade}>{grade}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                }
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialogAndResetForm}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" form="regradeLot" disabled={selectedGrade === null}>
                        Regrade
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={regradeLotWithComment} />
        </>
    );
}

export default RegradeDialog;