import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Accordion } from 'react-bootstrap';
import { faTriangleExclamation, faComment, faMessage, faMagnifyingGlass, faNoteSticky, faTimeline, faFlask } from '@fortawesome/free-solid-svg-icons';

import LotDetailSummary from './LotDetailsComponents/Summary';
import WarningsSection from './LotDetailsComponents/WarningsSection';
import GradingCommentsSection from './LotDetailsComponents/GradingCommentsSection';
import CommentsSection from './LotDetailsComponents/CommentsSection';
import QualitiesSection from './LotDetailsComponents/QualitiesSection';
import GenealogySection from './LotDetailsComponents/GenealogySection';
import QIRSection_QIRMS from './LotDetailsComponents/QIRSection_QIRMS';
import LabResultsSection from './LotDetailsComponents/LabResultsSection_BAPP';
import { LotDetailSection } from './LotDetailSection';
import { SectionNavigation } from './SectionNavigation_BAPP';

function LotDetails_BAPP({ lotDetail, getLotDetail, setLotDetail, loading, setLoading }) {

    const { t } = useTranslation();

    const hasOpenQirs = (qirs) => {
        if (qirs.length === 0) {
            return false;
        }

        return qirs.some(q => q.status === null || q.status === "Open")
    }

    const getAccordionHeaderClass = (title) => {
        let warningClassName = "warning";

        switch (title) {
            case "warnings":
                return lotDetail.warnings.length > 0 ? warningClassName : "";
            case "qualities":
                return lotDetail.qualities.some(q => q.isOffspec) ? warningClassName : "";
            case "labresults":
                return lotDetail.labResults.some(l => l.isOffspec) ? warningClassName : "";
            case "qirs":
                return hasOpenQirs(lotDetail.qirs) ? warningClassName : "";
            default:
                return "";
        }
    }

    return (
        <>
            <Container className={loading ? "fade-content" : ""}>
                {lotDetail &&
                    <>
                        <LotDetailSummary lotNumber={lotDetail.summary.lotNumber} summary={lotDetail.summary} revisions={lotDetail.revisions} getLotDetail={getLotDetail}
                            setLoading={setLoading} warnings={lotDetail.warnings} comments={lotDetail.comments} noSplitting={true} />

                        <SectionNavigation />

                        <Accordion defaultActiveKey={['warnings', 'gradingcomments', 'comments', 'qualities', 'genealogy', 'qirs', 'labresults']} alwaysOpen className="mb-2">
                        <LotDetailSection name="warnings"
                            className={`${getAccordionHeaderClass("warnings")}`}
                            icon={faTriangleExclamation}
                            title={t('LotDetails.Warnings.Title')}
                            component={
                                <WarningsSection warnings={lotDetail.warnings} lotNumber={lotDetail.summary.lotNumber}
                                    getLotDetail={getLotDetail} businessUnitCode={lotDetail.summary.businessUnitCode} />
                            } />
                        <LotDetailSection name="qirs"
                            className={`${getAccordionHeaderClass("qirs")}`}
                            icon={faNoteSticky}
                            title={t('LotDetails.QIRs.Title')}
                            component={
                                <QIRSection_QIRMS
                                    qirs={lotDetail.qirs}
                                    showCreateQIRButton={true}
                                    businessUnit={lotDetail.summary.businessUnitCode}
                                    lotNumber={lotDetail.summary.lotNumber}
                                    line={lotDetail.summary.productionLine}
                                    grade={lotDetail.summary.grade} />
                            } />
                        <LotDetailSection name="qualities"
                            className={`${getAccordionHeaderClass("qualities")}`}
                            icon={faMagnifyingGlass}
                            title={t('LotDetails.Qualities.Title')}
                            component={
                                <QualitiesSection qualities={lotDetail.qualities} businessUnitCode={lotDetail.summary.businessUnitCode} lotNumber={lotDetail.summary.lotNumber}
                                    lotDetail={lotDetail} setLotDetail={setLotDetail} />
                            } />
                        <LotDetailSection name="labresults"
                            className={`${getAccordionHeaderClass("labresults")}`}
                            icon={faFlask}
                            title={`${t('LotDetails.LabResults.Title')} (${lotDetail.labResults.length})`}
                            component={
                                <LabResultsSection labResults={lotDetail.labResults} />
                            } />
                        <LotDetailSection name="genealogy"
                            icon={faTimeline}
                            title={t('LotDetails.Genealogy.Title')}
                            component={
                                <GenealogySection genealogy={lotDetail.genealogy} lotNumber={lotDetail.summary.lotNumber} />
                            } />
                        <LotDetailSection name="gradingcomments"
                            icon={faComment}
                            title={t('LotDetails.GradingComments.Title')}
                            component={
                                <GradingCommentsSection gradingComments={lotDetail.gradingComments} lotNumber={lotDetail.summary.lotNumber}
                                    getLotDetail={getLotDetail} currentRevision={lotDetail.summary.lotRevisionId} />
                            } />
                        <LotDetailSection name="comments"
                            icon={faMessage}
                            title={t('LotDetails.Comments.Title')}
                            component={
                                <CommentsSection comments={lotDetail.comments} />
                            } />
                        </Accordion>
                    </>
                }
            </Container>
        </>
    )
}

export default LotDetails_BAPP;