import React, { useState, useEffect, Suspense } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import NavMenu from './components/NavMenu';
import LotList from './components/Pages/LotList';
import LotDetailsSelector from './components/Pages/LotDetails/LotDetailsSelector';
import UserPreferences from './components/Pages/UserPreferences';
import Reporting from './components/Pages/Reporting';
import ChangeLog from './components/Pages/ChangeLog';
import i18n from "i18next";

import Configuration from './components/Pages/Configuration';
import BusinessUnitConfiguration from './components/Pages/Configuration/BusinessUnits';
import ConfigurationDataConfiguration from './components/Pages/Configuration/ConfigurationData';
import EquipmentTypeConfiguration from './components/Pages/Configuration/EquipmentTypes';
import EquipmentConfiguration from './components/Pages/Configuration/Equipment';
import QualityConfiguration from './components/Pages/Configuration/Qualities';
import LineConfiguration from './components/Pages/Configuration/Lines';
import LineQualityDataConfiguration from './components/Pages/Configuration/LineQualityData';
import LabResultsConfigConfiguration from './components/Pages/Configuration/LabResultsConfig';
import RecipeTypeConfiguration from './components/Pages/Configuration/RecipeTypes';
import RecipePropertyConfiguration from './components/Pages/Configuration/RecipeProperties';
import RecipeDataTypeConfiguration from './components/Pages/Configuration/RecipeDataTypes';
import RecipeConfiguration from './components/Pages/Configuration/Recipes';
import GradedReasonsConfiguration from './components/Pages/Configuration/GradedReasons';

import NotificationAlert from './components/Alerts/NotificationAlert';
import Footer from './components/Footer';
import UserContext from './components/Contexts/UserContext';
import ConfigurationContext from './components/Contexts/ConfigurationContext';
import { AlertProvider } from './components/Contexts/AlertContext';
import { LotProvider } from './components/Contexts/LotContext';
import { fetchDataAuthenticated } from './components/Utilities/HttpUtils';
import { useIdleTimer } from "react-idle-timer";
import { signOut } from './components/Utilities/AuthUtils';
import { useSiteCode } from './components/Utilities/useSiteCode';

import './custom.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function LegacyApp(props) {

    const { t } = props;

    const defaultIdleTimeout = 30;
    const [idleTimeout, setIdleTimeout] = useState(defaultIdleTimeout);
    useIdleTimer({
        onIdle: () => signOut(),
        timeout: 1000 * 60 * idleTimeout
    });

    const [userData, setUserData] = useState({
        userName: "",
        privileges: []
    });

    const [configuration, setConfiguration] = useState({
        configurationData: []
    });

    const siteCode = useSiteCode();

    useEffect(() => {
        if (siteCode !== undefined) {
            console.log(`Changing translations for site namespace '${siteCode}'`);
            i18n.setDefaultNamespace(siteCode.toLowerCase());
            i18n.changeLanguage(i18n.language);
        }
    }, [siteCode]);

    useEffect(() => {

        fetchDataAuthenticated('api/v1/user')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setUserData({
                    userName: data.userName,
                    privileges: data.privileges
                });
            })
            .catch(error => {
                console.log("Failed to retrieve user info");

                setUserData({
                    ...userData,
                    username: "<unknown>",
                    privileges: []
                });
            });

        fetchDataAuthenticated('api/v1/configurationdata/?includeDeleted=false')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {

                setConfiguration({
                    ...configuration,
                    configurationData: data
                });
            })
            .catch(error => {
                console.log("Failed to retrieve configuration data");
            });

        fetchDataAuthenticated('api/config/idletimeout')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setIdleTimeout(data);
            })
            .catch(error => {
                console.log(`Failed to get idle timeout from config. Setting default value '${defaultIdleTimeout}'`);
            });
    }, []);


    let titleSuffix = "";
    if (window.location.href.includes("dev")) {
        titleSuffix = "(Development)";
    } else if (window.location.href.includes("acc")) {
        titleSuffix = "(Acceptance)"
    }

    return (
        <UserContext.Provider value={userData}>
            <ConfigurationContext.Provider value={configuration}>
                <HelmetProvider>
                    <AlertProvider>
                        <LotProvider>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NotificationAlert />
                                <NavMenu title={`${t("Application.Title")} ${titleSuffix}`} />

                                <Helmet>
                                    <title>{t("Application.TabTitle")}</title>
                                </Helmet>

                                <Routes>
                                    <Route path='/' exact element={<LotList />} />
                                    <Route path='/lots' exact element={<LotList />} />
                                    <Route path='/lots/ReadyToReview' exact element={<LotList status="ReadyToReview" tableName={"lots-table-ready-to-review"} />} />
                                    <Route path='/lots/ReadyToGrade' exact element={<LotList status="ReadyToGrade" tableName={"lots-table-ready-to-grade"} />} />
                                    <Route path='/lotdetails/:lotNumber' element={<LotDetailsSelector />} />
                                    <Route path='/config' exact element={<Configuration />} />
                                    <Route path='/config/businessunits' element={<BusinessUnitConfiguration />} />
                                    <Route path='/config/configurationdata' element={<ConfigurationDataConfiguration />} />
                                    <Route path='/config/equipmenttypes' element={<EquipmentTypeConfiguration />} />
                                    <Route path='/config/equipment' element={<EquipmentConfiguration />} />
                                    <Route path='/config/qualities' element={<QualityConfiguration />} />
                                    <Route path='/config/lines' element={<LineConfiguration />} />
                                    <Route path='/config/linequalitydata' element={<LineQualityDataConfiguration />} />
                                    <Route path='/config/labresultsconfig' element={<LabResultsConfigConfiguration />} />
                                    <Route path='/config/recipetypes' element={<RecipeTypeConfiguration />} />
                                    <Route path='/config/recipeproperties' element={<RecipePropertyConfiguration />} />
                                    <Route path='/config/recipedatatypes' element={<RecipeDataTypeConfiguration />} />
                                    <Route path='/config/recipes' element={<RecipeConfiguration />} />
                                    <Route path='/config/gradedreasons' element={<GradedReasonsConfiguration />} />
                                    <Route path='/user' element={<UserPreferences />} />
                                    <Route path='/reporting' element={<Reporting />} />
                                    <Route path='/changelog' element={<ChangeLog />} />
                                </Routes>

                                <Footer />
                            </Suspense>
                        </LotProvider>
                    </AlertProvider>
                </HelmetProvider>
            </ConfigurationContext.Provider>
        </UserContext.Provider>
    );
}

const WrappedLegacyApp = withTranslation()(LegacyApp)

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
    return (
        <Suspense fallback="loading">
            <WrappedLegacyApp />
        </Suspense>
    );
}