import React from 'react';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LotDetailSection({ name, className, icon, title, component }) {
    return (
        <Accordion.Item id={name + "-section"} eventKey={name}>
            <Accordion.Header className={className}>
                {icon &&
                    <FontAwesomeIcon icon={icon} className="me-3" />}
                {title}
            </Accordion.Header>
            <Accordion.Body>
                {component}
            </Accordion.Body>
        </Accordion.Item>
    );
}
