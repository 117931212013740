import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from './HttpUtils';

const baseUrl = "api/v1";

export function useBusinessUnits(includeDeleted) {
    const [businessUnits, setBusinessUnits] = useState([]);

    useEffect(() => {
        const getBusinessUnits = async function () {
            await fetchDataAuthenticated(`api/v1/businessUnit/?includeDeleted=${includeDeleted}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setBusinessUnits(data);
                })
                .catch(error => {
                    console.log("Failed to get buisiness units: " + error);
                });
        }

        getBusinessUnits();
    }, [includeDeleted]);

    return businessUnits;
}

export function useEquipmentTypes(includeDeleted, businessUnitId) {
    const [equipmentTypes, setEquipmentTypes] = useState([]);

    useEffect(() => {
        const getEquipmentTypes = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/equipmentType/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setEquipmentTypes(data);
                    })
                    .catch(error => {
                        console.error("Failed to get equipment types: " + error.message);
                    });
            }
            else {
                setEquipmentTypes([]);
            }
        }

        getEquipmentTypes();

    }, [businessUnitId, includeDeleted]);

    return equipmentTypes;
}

export function useLines(includeDeleted, businessUnitId) {
    const [lines, setLines] = useState([]);

    useEffect(() => {
        const getLines = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`api/v1/line/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong.');
                    })
                    .then(data => {
                        setLines(data);
                    })
                    .catch(error => {
                        console.log("Failed to get lines: " + error);
                    });
            }
            else {
                setLines([]);
            }
        }

        getLines();

    }, [businessUnitId, includeDeleted]);

    return lines;
}

export function useLinesMultipleBusinessUnit(businessUnitIds) {
    const [lines, setLines] = useState([]);

    useEffect(() => {
        const getLinesByBusinessUnit = async function () {
            fetchDataAuthenticated(`api/v1/line/multiplebusinessunit?businessUnitIds=${businessUnitIds}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(await response.json());
                })
                .then(data => {
                    setLines(data);
                })
                .catch(error => {
                    console.error("Failed to get lines by business units: " + error.message);
                });
        }

        getLinesByBusinessUnit()

    }, [businessUnitIds]);

    return lines;
}

export function useQualities(includeDeleted, businessUnitId) {
    const [qualities, setQualities] = useState([]);

    useEffect(() => {
        const getQualities = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/quality/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setQualities(data);
                    })
                    .catch(error => {
                        console.error("Failed to get qualities: " + error.message);
                    });
            }
            else {
                setQualities([]);
            }
        }

        getQualities();

    }, [businessUnitId, includeDeleted]);

    return qualities;
}

export function useEquipment(includeDeleted, businessUnitId) {
    const [equipment, setEquipment] = useState([]);

    useEffect(() => {
        const getEquipment = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/equipment/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setEquipment(data);
                    })
                    .catch(error => {
                        console.error("Failed to get equipment: " + error.message);
                    });
            }
            else {
                setEquipment([]);
            }
        }

        getEquipment();

    }, [businessUnitId, includeDeleted]);

    return equipment;
}

export function useRecipeTypes(includeDeleted, businessUnitId) {
    const [recipeTypes, setRecipeTypes] = useState([]);

    useEffect(() => {
        const getRecipeTypes = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/recipetype/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setRecipeTypes(data);
                    })
                    .catch(error => {
                        console.error("Failed to get recipe types: " + error.message);
                    });
            }
            else {
                setRecipeTypes([]);
            }
        }

        getRecipeTypes();

    }, [businessUnitId, includeDeleted]);

    return recipeTypes;
}

export function useRecipeProperties(includeDeleted, businessUnitId) {
    const [recipeProperties, setRecipeProperties] = useState([]);

    useEffect(() => {
        const getRecipeProperties = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/recipeproperty/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setRecipeProperties(data);
                    })
                    .catch(error => {
                        console.error("Failed to get recipe types: " + error.message);
                    });
            }
            else {
                setRecipeProperties([]);
            }
        }

        getRecipeProperties();

    }, [businessUnitId, includeDeleted]);

    return recipeProperties;
}

export function useRecipeDataTypes(includeDeleted, businessUnitId) {
    const [recipeDataTypes, setRecipeDataTypes] = useState([]);

    useEffect(() => {
        const getRecipeDataTypes = async function () {
            if (businessUnitId > 0) {
                await fetchDataAuthenticated(`${baseUrl}/recipedatatype/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(await response.json());
                    })
                    .then(data => {
                        setRecipeDataTypes(data);
                    })
                    .catch(error => {
                        console.error("Failed to get recipe data types: " + error.message);
                    });
            }
            else {
                setRecipeDataTypes([]);
            }
        }

        getRecipeDataTypes();

    }, [businessUnitId, includeDeleted]);

    return recipeDataTypes;
}

export function useCalcFunctions() {
    const [calcFunctions, setCalcFunctions] = useState([]);

    useEffect(() => {
        const getCalcFunctions = async function () {
            await fetchDataAuthenticated(`${baseUrl}/qualityfunctions/GetQualityCalculateFunctions`)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(await response.json());
                })
                .then(data => {
                    setCalcFunctions(data);
                })
                .catch(error => {
                    console.error("Failed to get calc functions: " + error.message);
                });
        }

        getCalcFunctions();

    }, []);

    return calcFunctions;
}

export function useLabFunctions() {
    const [labFunctions, setLabFunctions] = useState([]);

    useEffect(() => {
        const getLabFunctions = async function () {
            await fetchDataAuthenticated(`${baseUrl}/qualityfunctions/GetLabCalculateFunctions`)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(await response.json());
                })
                .then(data => {
                    setLabFunctions(data);
                })
                .catch(error => {
                    console.error("Failed to get lab functions: " + error.message);
                });
        }

        getLabFunctions();

    }, []);

    return labFunctions;
}

export function useOffspecFunctions() {
    const [offspecFunctions, setOffspecFunctions] = useState([]);

    useEffect(() => {
        const getOffspecFunctions = async function () {
            await fetchDataAuthenticated(`${baseUrl}/qualityfunctions/GetQualityOffspecFunctions`)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(await response.json());
                })
                .then(data => {
                    setOffspecFunctions(data);
                })
                .catch(error => {
                    console.log("Failed to get offspec functions: " + error.message);
                });
        }

        getOffspecFunctions();

    }, []);

    return offspecFunctions;
}

export function useGetSplittableBatches(lotNumber) {
    const [loading, setLoading] = useState(true);
    const [splittableBatches, setSplittableBatches] = useState([]);

    useEffect(() => {
        setLoading(true);
        getSplittableBatches(lotNumber);
    }, [lotNumber]);

    async function getSplittableBatches(lotNumber) {
        await fetchDataAuthenticated(`${baseUrl}/lot/${lotNumber}/splittablebatches`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.json());
            })
            .then(data => {
                setSplittableBatches(data);
            })
            .catch(error => {
                console.error("Failed to get splittable batches for lot '" + lotNumber + "': " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { splittableBatches, loading };
}

export function useGetSplittableSilos(lotNumber) {
    const [loading, setLoading] = useState(true);
    const [splittableSilos, setSplittableSilos] = useState([]);

    useEffect(() => {
        setLoading(true);
        getSplittableSilos(lotNumber);
    }, [lotNumber]);

    async function getSplittableSilos(lotNumber) {
        await fetchDataAuthenticated(`${baseUrl}/lot/${lotNumber}/splittablesilos`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.json());
            })
            .then(data => {
                setSplittableSilos(data);
            })
            .catch(error => {
                console.error("Failed to get splittable silos for lot '" + lotNumber + "': " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { splittableSilos, loading };
}