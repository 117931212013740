import React, { useState, useContext } from 'react';
import { Container, Button, ToggleButton, Row, Col } from 'react-bootstrap';
import UserContext from '../../../components/Contexts/UserContext';

function CommonConfigPage({ children, title, table, dialog, getNewConfigObject, pageMetaDataLabel, pageMetaData }) {

    if (!title) {
        console.warn("Prop 'title' is used as an identifier in this component. Please supply the prop with a value.")
    }

    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [refreshList, setRefreshList] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ name: "" });

    const userContext = useContext(UserContext);
    const permissionToConfigure = userContext.privileges.length > 0 && userContext.privileges.includes("CanConfigure");

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData(getNewConfigObject());
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        setRefreshList(refreshList + 1);
    };

    return (
        <Container fluid="xs" className="mb-3">
            <Row>
                <Col>
                    {!!pageMetaData &&
                        <div className="d-flex justify-content-end text-secondary">
                            <span className="me-1">{pageMetaDataLabel}</span>
                            <span>{pageMetaData}</span>
                        </div>
                    }
                    {table(includeDeleted, showEditDialog, refreshList)}
                </Col>
            </Row>
            <Row>
                <Col>
                    <ToggleButton
                        className="mb-2 float-start"
                        id={"toggle-check-" + title.toLowerCase().replaceAll(' ', '')}
                        type="checkbox"
                        variant="outline-primary"
                        checked={includeDeleted}
                        value="1"
                        onChange={e => setIncludeDeleted(e.currentTarget.checked)}
                    >
                        Include Deleted
                    </ToggleButton>
                    {children}
                    {!!getNewConfigObject &&
                        <Button className="float-end" variant="primary" disabled={!permissionToConfigure} onClick={showNewDialog}>Add</Button>
                    }
                </Col>
            </Row>

            {dialog(dialogData, setDialogData, showDialog, closeDialog)}

        </Container>
    );
}

export default CommonConfigPage;