import React, { useState, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import { Spinner } from 'react-bootstrap';
import { fetchDataAuthenticated, correlationId } from '../Utilities/HttpUtils';
import useAlert from '../Alerts/useAlert';
import { useSiteCode } from '../Utilities/useSiteCode';

import LotListTable from './LotListTable';

function LotListTableSelector({tableFilters, tableName}) {

    const siteCode = useSiteCode();

    const { setAlert } = useAlert()

    const [loading, setLoading] = useState(true);
    const [lots, setLots] = useState([]);

    useEffect(() => {
        setLoading(true);
        getLots();
    }, [tableFilters.status, tableFilters.dateFilter, tableFilters.includeGraded, tableFilters.selectedBusinessUnits, tableFilters.selectedLines]);

    // update table at interval
    useInterval(async () => {
        getLots();
    }, 30000);

    async function getLots() {
        let params = new URLSearchParams();

        if (tableFilters.status) {
            params.append("status", tableFilters.status);
        }

        if (tableFilters.dateFilter?.from) {
            params.append("dateFrom", tableFilters.dateFilter.from);
        }

        if (tableFilters.dateFilter?.to) {
            params.append("dateTo", tableFilters.dateFilter.to);
        }

        if (tableFilters.includeGraded) {
            params.append("includeGraded", tableFilters.includeGraded);
        }

        if (tableFilters.selectedBusinessUnits.length > 0) {
            params.append("businessUnits", tableFilters.selectedBusinessUnits);
        }

        if (tableFilters.selectedLines.length > 0) {
            params.append("lines", tableFilters.selectedLines);
        }

        await fetchDataAuthenticated("api/v1/lots?" + params.toString())
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error(`Failed to get lots [${correlationId}]`)
            })
            .then(data => {
                setLots(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    switch (siteCode) {
        
        default:
            return <LotListTable lots={lots} tableFilters={ tableFilters} tableName={tableName} />
    }
}

export default LotListTableSelector;