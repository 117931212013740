import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import reportWebVitals from './reportWebVitals';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { client as publicClientApplication} from './components/Utilities/AuthUtils';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

publicClientApplication.initialize();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <React.StrictMode>
        <MsalProvider instance={publicClientApplication}>
            <BrowserRouter basename={baseUrl}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <App />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                    <p>Unauthorized</p>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </ MsalProvider>
    </React.StrictMode>);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

