import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form, Spinner, SplitButton, Dropdown } from 'react-bootstrap';
import GradedCommentDialog from "../../../Dialogs/GradedCommentDialog";
import CommentDialog from "../../../Dialogs/CommentDialog";
import WarningDialog from "../../../Dialogs/WarningDialog";
import GradedWarningDialog from "../../../Dialogs/GradedWarningDialog";
import { fetchDataAuthenticated, fetchBlobAuthenticated } from '../../../Utilities/HttpUtils';
import { DateToLocaleString, longDateAndTimeFormat } from '../../../Utilities/DateUtils';
import LotSplitDialog from '../../LotSplit/LotSplitDialog';
import LotSplitDialog_HCC from '../../LotSplit/LotSplitDialog_HCC';
import RegradeDialog from '../../../Dialogs/RegradeDialog';
import CancelLotSplitDialog from '../../LotSplit/CancelLotSplitDialog';
import useAlert from '../../../Alerts/useAlert';
import { usePrivileges } from '../../../Auth/usePrivileges';
import LotContext from '../../../Contexts/LotContext';
import RenameLotDialog from '../../../Dialogs/RenameLotDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function LotDetailsSummary({ lotNumber, summary, revisions, getLotDetail, setLoading, warnings, comments, noSplitting }) {

    const { t } = useTranslation();
    const { setAlert } = useAlert();
    const { canProcess, canReview, canGrade, supervisor, admin } = usePrivileges(summary.businessUnitCode);
    const lotContext = useContext(LotContext);
    const navigate = useNavigate();

    const [disableProcessAgain, setDisableProcessAgain] = useState(false);
    const [disableConfirmReview, setDisableConfirmReview] = useState(false);
    const [disableConfirmGrade, setDisableConfirmGrade] = useState(false);
    const [disableUndoGraded, setDisableUndoGraded] = useState(false);
    const [disableSplitLot, setDisableSplitLot] = useState(false);
    const [showRegradeButton, setShowRegradeButton] = useState(false);

    const [showProcessAgainCommentDialog, setShowProcessAgainCommentDialog] = useState(false);
    const [showFullRecalculateCommentDialog, setShowFullRecalculateCommentDialog] = useState(false);
    const [showReviewCommentDialog, setShowReviewCommentDialog] = useState(false);
    const [showGradeCommentDialog, setShowGradeCommentDialog] = useState(false);
    const [showUndoGradedCommentDialog, setShowUndoGradedCommentDialog] = useState(false);
    const [showLotSplitDialog, setShowLotSplitDialog] = useState(false);
    const [showCancelLotSplitDialog, setShowCancelLotSplitDialog] = useState(false);
    const [showRegradeDialog, setShowRegradeDialog] = useState(false);
    const [showDeleteLotCommentDialog, setShowDeleteLotCommentDialog] = useState(false);
    const [showChangeLotRevisionCommentDialog, setShowChangeLotRevisionCommentDialog] = useState(false);
    const [showRenameLotButton, setShowRenameLotButton] = useState(false);
    const [showRenameLotDialog, setShowRenameLotDialog] = useState(false);

    const [loadingAlternativeGrades, setLoadingAlternativeGrades] = useState(false);
    const [alternativeGrades, setAlternativeGrades] = useState([]);
    const [loadingGrades, setLoadingGrades] = useState(false);
    const [grades, setGrades] = useState([]);

    const [loadingGradedReasons, setLoadingGradedReasons] = useState(false);
    const [gradedReasons, setGradedReasons] = useState([]);

    const [loadingReport, setLoadingReport] = useState(false);
    const [loadingProcessData, setLoadingProcessData] = useState(false);
    const [loadingCollectionUpdate, setLoadingCollectionUpdate] = useState(false);


    useEffect(() => {

        setDisableProcessAgain(!canProcess);
        setDisableConfirmReview(!canReview);
        setDisableSplitLot(!canReview);
        setDisableConfirmGrade(!canGrade);

        switch (summary.processingStatus) {
            case "Processing":
            case "Queued":
                if (!admin) {
                    // NB, Admins can ignore queued and processing status. May be necessary to unblock lots in some failure situations
                    setDisableConfirmReview(true);
                    setDisableConfirmGrade(true);
                    setDisableUndoGraded(true);
                    setDisableSplitLot(true);
                    setDisableProcessAgain(true);
                }
                break;

            default:
                switch (summary.currentStatus) {
                    case "Importing":
                        setDisableConfirmReview(true);
                        setDisableConfirmGrade(true);
                        setDisableUndoGraded(true);
                        setDisableSplitLot(true);
                        break;
                    case "Processing":
                        setDisableConfirmReview(true);
                        setDisableConfirmGrade(true);
                        setDisableUndoGraded(true);
                        break;
                    case "ReadyToReview":
                        setDisableConfirmGrade(true);
                        setDisableUndoGraded(true);
                        break;
                    case "ReadyToGrade":
                        setDisableConfirmReview(true);
                        setDisableUndoGraded(true);
                        break;
                    case "Graded":
                        setDisableProcessAgain(true);
                        setDisableConfirmReview(true);
                        setDisableConfirmGrade(true);
                        setDisableSplitLot(true);
                        break;
                    default:
                        setDisableProcessAgain(true);
                        setDisableConfirmReview(true);
                        setDisableConfirmGrade(true);
                        setDisableUndoGraded(true);
                        setDisableSplitLot(true);
                }
        }

        if (summary.processingStatus !== "Queued" && summary.processingStatus !== "Processing" && summary.currentStatus !== "Graded") {
            getAlternativeGrades(lotNumber);
            getGrades(summary.businessUnitCode);
            getGradedReasons(summary.businessUnitCode);

            if (canGrade) {
                setShowRegradeButton(true);
            }
        }

    }, [canProcess, canReview, canGrade, supervisor, admin, summary]);

    const getAlternativeGrades = (lotNumber) => {
        if (!loadingAlternativeGrades) {
            setLoadingAlternativeGrades(true);

            fetchDataAuthenticated(`api/v1/grade/lot?lotNumber=${lotNumber}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Failed to get alternative grades for lot");
                    }
                })
                .then(data => setAlternativeGrades(data))
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                })
                .finally(() => setLoadingAlternativeGrades(false));
        }
    }

    const getGrades = (businessUnitCode) => {
        if (!loadingGrades) {
            setLoadingGrades(true);

            fetchDataAuthenticated(`api/v1/grade?businessUnitCode=${businessUnitCode}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Failed to get grades for business unit");
                    }
                })
                .then(data => setGrades(data))
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                })
                .finally(() => setLoadingGrades(false));
        }
    }

    const getGradedReasons = (businessUnitCode) => {
        if (!loadingGradedReasons) {
            setLoadingGradedReasons(true);

            let url = `api/v1/gradedreason/?includeDeleted=false`;
            if (businessUnitCode)
                url += `&businessUnitCode=${businessUnitCode}`;

            fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Failed to get graded reason codes for business unit");
                    }
                })
                .then(data => setGradedReasons(data))
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                })
                .finally(() => setLoadingGradedReasons(false));
        }
    }

    const updateLotStatus = async (status, comment, reasonCode) => {

        let url = `/api/v1/lot/${lotNumber}/status/${status}`;

        if (reasonCode) {
            url += "?reasoncode=" + reasonCode
        }

        setLoading(true);
        await fetchDataAuthenticated(url, 'PUT', JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", `Updated the lot status to ${status}`);
                    getLotDetail();
                    lotContext.updateCounters();
                } else {
                    let errorMessage = await response.text();
                    console.log("Failed to update the lot status: " + errorMessage);
                    throw new Error("Failed to update the lot status.");
                }
            })
            .catch(error => {
                setLoading(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const handleProcessAgainCommentSave = (comment) => {
        updateLotStatus("processing", comment);
    }

    const handleReviewCommentSave = (comment) => {
        updateLotStatus("readyToGrade", comment);
    }

    const handleGradeCommentSave = (comment, gradedReason) => {
        updateLotStatus("graded", comment, gradedReason);
    }

    const handleFullRecalculateCommentSave = async (comment) => {
        setLoading(true);
        await fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/fullRecalculate`, 'PUT', JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", `Flagged lot to fully recalculate`);
                    getLotDetail();
                    lotContext.updateCounters();
                } else {
                    let errorMessage = await response.text();
                    console.log("Failed to flag lot to fully recalculate: " + errorMessage);
                    throw new Error("Failed to flag lot to fully recalculate.");
                }
            })
            .catch(error => {
                setLoading(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const handleUndoGradedCommentSave = async (comment) => {
        setLoading(true);
        await fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/status/undoGraded`, 'PUT', JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", "Graded status on the lot has been undone");
                    getLotDetail();
                    lotContext.updateCounters();
                } else {
                    let errorMessage = await response.text();
                    throw new Error("Failed to undo the graded status on lot: " + errorMessage);
                }
            })
            .catch(error => {
                setLoading(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const viewReport = async (regenerateReport) => {
        setLoadingReport(true);

        const revisionId = summary.lotRevisionId;

        let url = `api/v1/lot/${lotNumber}/report`;

        if (revisionId) {
            url += `?revisionId=${revisionId}`;
        }

        url += `&regenerateReport=${regenerateReport}`;

        await fetchBlobAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "application/pdf");
            })
            .then(blob => {
                setLoadingReport(false);
                return URL.createObjectURL(blob);
            })
            .then(url => window.open(url))
            .catch(error => {
                setLoadingReport(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const viewLotSplitReport = async () => {
        setLoadingReport(true);

        const revisionId = summary.lotRevisionId;

        let url = `api/v1/lot/${lotNumber}/lotsplitreport`;

        await fetchBlobAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "text/html; charset=utf-8");
            })
            .then(blob => {
                setLoadingReport(false);
                return URL.createObjectURL(blob);
            })
            .then(url => window.open(url))
            .catch(error => {
                setLoadingReport(false);
                setAlert("danger", "Error", error.message);
            });
    }


    const downloadFile = (blob, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click',
            {
                view: window,
                bubbles: true,
                cancelable: true,
            });
        a.dispatchEvent(clickEvt);
        a.remove();
    }

    const exportProcessData = async () => {
        setLoadingProcessData(true);

        const revisionId = summary.lotRevisionId;

        let url = `api/v1/lot/${lotNumber}/processData`;

        if (revisionId) {
            url += `?revisionId=${revisionId}`;
        }

        await fetchBlobAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "application/csv");
            })
            .then(blob => {
                setLoadingProcessData(false);
                downloadFile(blob, `ProcessData-${lotNumber}.csv`);
            })
            .catch(error => {
                setLoadingProcessData(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const exportCollectionUpdate = () => {
        setLoadingCollectionUpdate(true);

        const revisionId = summary.lotRevisionId;

        let url = `api/v1/lot/${lotNumber}/collectionUpdate`;

        if (revisionId) {
            url += `?revisionId=${revisionId}`;
        }

        fetchBlobAuthenticated(url)
            .then(response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error("Failed to get Collection Update.");
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "application/xml");
            })
            .then(blob => {
                setLoadingCollectionUpdate(false);
                downloadFile(blob, `CollectionUpdate-${lotNumber}.xml`);
            })
            .catch(error => {
                setLoadingCollectionUpdate(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const deleteLot = async (comment) => {

        let url = `api/v1/lot/${lotNumber}`;

        setLoading(true);

        fetchDataAuthenticated(url, 'DELETE', JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", `Deleted lot ${lotNumber}`);
                    navigate('/lots');
                } else {
                    let message = await response.text();
                    throw new Error(`Failed to delete the lot. ${message}`);
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                setLoading(false);
            });
    }

    const changeLotRevision = (comment) => {

        const revisionId = summary.lotRevisionId;

        let url = `api/v1/lot/${lotNumber}/revision`;
        if (revisionId) {
            url += `?revisionId=${revisionId}`;
        }

        setLoading(true);

        fetchDataAuthenticated(url, 'PUT', JSON.stringify(comment))
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Success", `Changed lot ${lotNumber} to revision ${revisionId}`);
                    getLotDetail();
                } else {
                    throw new Error("Failed to change the lot revision.");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                setLoading(false);
            });
    }

    // Get revision date to show in the summary so it is obvious we are not viewing the latest
    const getRevisionDate = (lotRevisionId) => {

        let revision = revisions.find(revision => revision.lotRevisionId === lotRevisionId);

        return DateToLocaleString(revision.revisionDateUTC, longDateAndTimeFormat);
    }

    // Check we are viewing the latest revision of the lot
    const isLatestRevision = (lotRevisionId) => {

        let revision = revisions.find(revision => revision.lotRevisionId === lotRevisionId);

        return revision.revisionDateUTC === summary.latestRevisionDate;
    }

    const lotContainsUnreliableBaggingData = () => {
        return comments.some(x => x.commentText.includes("Unreliable extruder times for loadout counts"));
    }

    const LotSplitDialogSelector = ({ businessUnitCode, showDialog, closeDialog, lotNumber, buildStart, buildEnd, getLotDetail, currentGrade, alternativeGrades, allGrades, loadingAlternativeGrades, loadingAllGrades, unreliableBaggingData }) => {
        switch (businessUnitCode) {
            case "HCC_GPPE":
            case "HCC_PP":
            case "HCC_LDPE":
                return <LotSplitDialog_HCC showDialog={showDialog} closeDialog={closeDialog} lotNumber={lotNumber} buildStart={buildStart} buildEnd={buildEnd} getLotDetail={getLotDetail}
                    currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} loadingAlternativeGrades={loadingAlternativeGrades} loadingAllGrades={loadingAllGrades} unreliableBaggingData={unreliableBaggingData} />

            default:
                return <LotSplitDialog showDialog={showDialog} closeDialog={closeDialog} lotNumber={lotNumber} buildStart={buildStart} buildEnd={buildEnd} getLotDetail={getLotDetail}
                    currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} loadingAlternativeGrades={loadingAlternativeGrades} loadingAllGrades={loadingAllGrades} unreliableBaggingData={unreliableBaggingData} />
        }
    }

    const allPrivilegesLoaded =
        canProcess !== undefined &&
        canReview !== undefined &&
        canGrade !== undefined &&
        supervisor !== undefined &&
        admin !== undefined;

    return (
        <>
            <div className="card p-3 mb-3 bg-light rounded-3">
                <Row>
                    <div className="d-flex flex-md-row flex-column">
                        <div title={t('LotDetails.Summary.LotNumber')}>
                            <div className="d-flex me-3 align-items-center" onMouseOver={() => setShowRenameLotButton(true)} onMouseOut={() => setShowRenameLotButton(false)}>
                                <h3>{summary.lotNumber}</h3>
                                {summary.currentStatus !== "Graded" &&
                                    <FontAwesomeIcon icon={faPen} className="ms-3" onClick={() => setShowRenameLotDialog(true)} style={{ "opacity": showRenameLotButton ? "1" : "0" }} />
                                }
                            </div>
                        </div>
                        {allPrivilegesLoaded &&
                            <div className="d-flex ms-md-auto align-items-center flex-md-row flex-column">
                                <Col sm>
                                    <SplitButton variant="primary"
                                        title={t('LotDetails.Summary.ProcessAgain')}
                                        onClick={() => setShowProcessAgainCommentDialog(true)}
                                        disabled={disableProcessAgain}>
                                        <Dropdown.Item onClick={() => setShowProcessAgainCommentDialog(true)}>{t('LotDetails.Summary.ProcessAgain')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setShowFullRecalculateCommentDialog(true)}>{t('LotDetails.Summary.FullReprocess')}</Dropdown.Item>
                                    </SplitButton>
                                    <Button variant="primary me-1 ms-1"
                                        onClick={() => setShowReviewCommentDialog(true)}
                                        disabled={disableConfirmReview}>
                                        {t('LotDetails.Summary.ConfirmReview')}
                                    </Button>
                                    <Button variant="primary me-1"
                                        onClick={() => setShowGradeCommentDialog(true)}
                                        disabled={disableConfirmGrade}>
                                        {t('LotDetails.Summary.ConfirmGraded')}
                                    </Button>
                                    <Button variant="primary me-1"
                                        onClick={() => setShowUndoGradedCommentDialog(true)}
                                        disabled={disableUndoGraded}
                                        hidden={!supervisor}>
                                        {t('LotDetails.Summary.UndoGraded')}
                                    </Button>
                                    <Button variant="primary me-1"
                                        onClick={() => setShowLotSplitDialog(true)}
                                        disabled={disableSplitLot}
                                        hidden={summary.isSplitLotParent || noSplitting}>
                                        {t('LotDetails.Summary.Split')}
                                    </Button>
                                    <Button variant="primary me-1"
                                        onClick={() => setShowCancelLotSplitDialog(true)}
                                        disabled={disableSplitLot}
                                        hidden={!summary.isSplitLotParent || noSplitting}>
                                        {t('LotDetails.Summary.UndoSplit')}
                                    </Button>

                                    {summary.currentStatus === "Graded" && !admin &&
                                        <Button variant="primary me-1"
                                            disabled={loadingReport}
                                            onClick={() => viewReport(false)}>
                                            {t('LotDetails.Summary.ViewReport')}
                                            {loadingReport &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="ms-2"
                                                />
                                            }
                                        </Button>
                                    }

                                    {(summary.currentStatus !== "Graded" || admin) &&
                                        <SplitButton variant="primary" className="me-1"
                                            title={
                                                <>
                                                    {t('LotDetails.Summary.ViewReport')}
                                                    {
                                                        loadingReport &&
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="ms-2"
                                                        />
                                                    }
                                                </>
                                            }
                                            onClick={() => viewReport(false)}
                                            disabled={loadingReport}>
                                            <Dropdown.Item onClick={() => viewReport(false)}>{t('LotDetails.Summary.ViewReport')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => viewReport(true)}>{t('LotDetails.Summary.ViewReportNoCache')}</Dropdown.Item>
                                            <Dropdown.Item disabled={!summary.isSplitLotParent}  onClick={() => viewLotSplitReport()}>{t('LotDetails.Summary.ViewLotSplitReport')}</Dropdown.Item>
                                        </SplitButton>
                                    }

                                    <Button variant="primary me-1"
                                        disabled={loadingProcessData}
                                        onClick={() => exportProcessData()}>
                                        {t('LotDetails.Summary.ExportOneMin')}
                                        {loadingProcessData &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ms-2"
                                            />
                                        }
                                    </Button>
                                    <Button variant="primary me-1"
                                        disabled={loadingCollectionUpdate}
                                        onClick={() => exportCollectionUpdate()}>
                                        {t('LotDetails.Summary.ExportLot')}
                                        {loadingCollectionUpdate &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="ms-2"
                                            />
                                        }
                                    </Button>
                                    <Button variant="danger me-1"
                                        hidden={!admin}
                                        onClick={() => setShowDeleteLotCommentDialog(true)}>
                                        {t('LotDetails.Summary.DeleteLot')}
                                    </Button>
                                </Col>
                            </div>
                        }
                    </div>
                </Row>
                <hr />
                <div>
                    <Row>
                        <Col sm={6}>
                            <Form.Group as={Row} className="mb-3" controlId="formGrade">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.Grade')}:</Form.Label>
                                <Col sm={8}>
                                    <div className="d-flex justify-content-start">
                                        <Form.Control type="text" disabled className="as-label" value={summary.grade} />
                                        <Button variant="light" hidden={!showRegradeButton} className="ms-1"
                                            onClick={() => setShowRegradeDialog(true)}>
                                            {t('LotDetails.Summary.Ellipses')}
                                        </Button>
                                    </div>
                                    <RegradeDialog showDialog={showRegradeDialog} closeDialog={() => setShowRegradeDialog(false)} lotNumber={lotNumber}
                                        getLotDetail={getLotDetail} alternativeGrades={alternativeGrades} grades={grades} loading={loadingAlternativeGrades || loadingGrades} />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <div className="d-flex justify-content-end align-items-center me-2">
                                {!isLatestRevision(summary.lotRevisionId) &&
                                    <span className="me-2">{t('LotDetails.Summary.ViewingRevision')} {getRevisionDate(summary.lotRevisionId)}</span>
                                }
                                {admin && !isLatestRevision(summary.lotRevisionId) &&
                                    <a className="link" onClick={() => setShowChangeLotRevisionCommentDialog(true)}>
                                        {t('LotDetails.Summary.SetAsLatestRevision')}
                                    </a>
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group as={Row} className="mb-1" controlId="formLine">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.Line')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled className="as-label" value={summary.productionLine} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formSize">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.LotSize')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={summary.lotWeight} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formBuildStart">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.BuildStart')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={DateToLocaleString(summary.buildStart)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formLotStatus">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.LotStatus')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={t('LotStatus.' + summary.currentStatus)} />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row} className="mb-1" controlId="formExtruder">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.Extruder')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={summary.extruder} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formLoadoutWeight">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.LoadoutWeight')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={summary.loadoutWeight} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formBuildEnd">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.BuildEnd')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={DateToLocaleString(summary.buildEnd)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1" controlId="formJobStatus">
                                <Form.Label column sm={4}>{t('LotDetails.Summary.JobStatus')}:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" disabled value={t('LotStatus.' + summary.processingStatus)} />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>

            <CommentDialog showDialog={showProcessAgainCommentDialog} closeDialog={() => setShowProcessAgainCommentDialog(false)} onSave={handleProcessAgainCommentSave} />
            <CommentDialog showDialog={showFullRecalculateCommentDialog} closeDialog={() => setShowFullRecalculateCommentDialog(false)} onSave={handleFullRecalculateCommentSave} />
            <CommentDialog showDialog={warnings.length === 0 && showReviewCommentDialog} closeDialog={() => setShowReviewCommentDialog(false)} onSave={handleReviewCommentSave} />
            <WarningDialog showDialog={warnings.length > 0 && showReviewCommentDialog} closeDialog={() => setShowReviewCommentDialog(false)} onSave={handleReviewCommentSave} action="review" warnings={warnings} />
            <GradedCommentDialog showDialog={warnings.length === 0 && showGradeCommentDialog} closeDialog={() => setShowGradeCommentDialog(false)} onSave={handleGradeCommentSave} gradedReasons={gradedReasons} />
            <GradedWarningDialog showDialog={warnings.length > 0 && showGradeCommentDialog} closeDialog={() => setShowGradeCommentDialog(false)} onSave={handleGradeCommentSave} action="grade" warnings={warnings} gradedReasons={gradedReasons} />
            <CommentDialog showDialog={showUndoGradedCommentDialog} closeDialog={() => setShowUndoGradedCommentDialog(false)} onSave={handleUndoGradedCommentSave} />
            <LotSplitDialogSelector businessUnitCode={summary.businessUnitCode} showDialog={showLotSplitDialog} closeDialog={() => setShowLotSplitDialog(false)} lotNumber={lotNumber} buildStart={summary.buildStart} buildEnd={summary.buildEnd} getLotDetail={getLotDetail}
                currentGrade={summary.grade} alternativeGrades={alternativeGrades} allGrades={grades} loadingAlternativeGrades={loadingAlternativeGrades} loadingAllGrades={loadingGrades} unreliableBaggingData={lotContainsUnreliableBaggingData()} />
            <CancelLotSplitDialog showDialog={showCancelLotSplitDialog} closeDialog={() => setShowCancelLotSplitDialog(false)} lotNumber={lotNumber} getLotDetail={getLotDetail} />
            <CommentDialog showDialog={showDeleteLotCommentDialog} closeDialog={() => setShowDeleteLotCommentDialog(false)} onSave={deleteLot} />
            <CommentDialog showDialog={showChangeLotRevisionCommentDialog} closeDialog={() => setShowChangeLotRevisionCommentDialog(false)} onSave={changeLotRevision} />
            <RenameLotDialog showDialog={showRenameLotDialog} closeDialog={() => setShowRenameLotDialog(false)} lotNumber={summary.lotNumber} />
        </>
    );
}

export default LotDetailsSummary;