import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

export function SectionNavigation() {
    const { t } = useTranslation();

    return (
        <Row className="gy-2 mb-2">
            <Col sm xs={3}>
                <HashLink to="#warnings-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.Warnings.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#qirs-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.QIRs.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#qualities-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.Qualities.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#labresults-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.LabResults.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#genealogy-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.Genealogy.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#gradingcomments-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.GradingComments.Title')}
                    </Badge>
                </HashLink>
            </Col>
            <Col sm xs={3}>
                <HashLink to="#comments-section">
                    <Badge bg="secondary" className="hashlink-text w-100 lighter">
                        {t('LotDetails.Comments.Title')}
                    </Badge>
                </HashLink>
            </Col>
        </Row>
    );
}
