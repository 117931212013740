import React, { useEffect } from "react";
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import { useGetSplittableSilos } from '../../Utilities/Hooks.js';
import { useTranslation } from 'react-i18next';

function LotSplitBySiloBuilder({ lotSplitSilos, setLotSplitSilos, updatedSplit, lotNumber, currentGrade, alternativeGrades, allGrades }) {

    const { t } = useTranslation();
    const { splittableSilos, loading } = useGetSplittableSilos(lotNumber);

    useEffect(() => {
        splittableSilos.sort();
        let tempLotSplitSilos = splittableSilos.map((silo, idx) => {
            return { silo: silo, suffix: "A", grade: currentGrade }
        })

        setLotSplitSilos(tempLotSplitSilos);

    }, [splittableSilos, setLotSplitSilos, currentGrade]);


    const handleUpdateRow = (e) => {

        let index = e.target.dataset.id;
        let prop = e.target.name;
        let value = e.target.value;

        let tempLotSplitSilos = [...lotSplitSilos];
        let tempLotSplitSilo = { ...tempLotSplitSilos[index] };

        tempLotSplitSilo[prop] = value;
        tempLotSplitSilos[index] = tempLotSplitSilo;

        setLotSplitSilos(tempLotSplitSilos);

        updatedSplit(index, prop, value);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-start me-auto">
                <Spinner animation="border" size="md" className="me-3" />
                <p className="mt-2">{t("LotSplit.LoadingSilos")}</p>
            </div>
        );
    }
    else if (lotSplitSilos.length === 0) {
        return (
            <Row className="mb-3">
                <Col sm={6}>{t("LotSplit.NoSplittableSilosMessage")}</Col>
            </Row>
        );
    }
    else {
        return (
            lotSplitSilos.map((lotSplitSilo, idx) => {

                let suffix = `suffix-${idx}`;
                let grade = `grade-${idx}`;

                return (
                    <Row key={idx} className="mb-3">
                        <Col sm={3}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Silo")}</p>
                            }
                            <Form.Group controlId={suffix}>
                                <Form.Control type="text" placeholder="Silo"
                                    name="suffix"
                                    data-id={idx}
                                    readOnly
                                    value={lotSplitSilo.silo}>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Suffix")}</p>
                            }
                            <Form.Group controlId={suffix}>
                                <Form.Control type="text" placeholder="Suffix"
                                    name="suffix"
                                    data-id={idx}
                                    required
                                    value={lotSplitSilo.suffix}
                                    maxLength={1}
                                    onChange={handleUpdateRow}>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Grade")}</p>
                            }
                            <Form.Group controlId={grade}>
                                <Form.Select
                                    aria-label="Select grade"
                                    name="grade"
                                    data-id={idx}
                                    required
                                    value={lotSplitSilo.grade}
                                    onChange={handleUpdateRow}>
                                    <option value="">Select...</option>
                                    <option value={currentGrade}>{currentGrade}</option>
                                    {alternativeGrades.map((grade, index) =>
                                        <option key={`grade-${index}`} value={grade.grade}>{grade.grade}</option>
                                    )}
                                    <option value="" disabled>------------</option>
                                    {allGrades.map((grade, index) =>
                                        <option key={`grade-${alternativeGrades.length + 1 + index}`} value={grade}>{grade}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                );
            })
        );
    }
}

export default LotSplitBySiloBuilder