import React, { useState, useEffect } from 'react';
import {Row, Col, Spinner, Button, Form} from 'react-bootstrap';
import DateRange from '../../DateRange';
import MultiselectBox from '../../Utilities/MultiselectBox';
import {fetchBlobAuthenticated, fetchDataAuthenticated} from '../../Utilities/HttpUtils';
import useAlert from '../../Alerts/useAlert';
import {useTranslation} from "react-i18next";
import { useSiteCode } from '../../Utilities/useSiteCode';
import ReportingQirListTable from './ReportingQirListTable';

function QualityIncidentReportsTable() {

    const { t } = useTranslation();

    const siteCode = useSiteCode();
    
    const getFromDate = () => {
        // Today
        let fromDate = new Date();

        // Last day of the previous month
        fromDate.setDate(0);

        // First day of previous month after setting the date to the last day of the previous month
        fromDate.setDate(1);

        // Unused but good to know.. 30 days ago...
        // todayDate.setDate(todayDate.getDate() - 30);

        return fromDate.toISOString().split('T')[0];
    }

    const getToDate = () => {
        // Today
        let toDate = new Date();
        
        // Last day of the previous month
        toDate.setDate(0);

        return toDate.toISOString().split('T')[0];
    }
    
    const defaultDateRange = {
        from: getFromDate(),
        to: getToDate()
    };

    const [loadingQirData, setLoadingQirData] = useState(false);

    const [businessUnits, setBusinessUnits] = useState();
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);

    const [lines, setLines] = useState();
    const [selectedLines, setSelectedLines] = useState([]);
    
    const [dateFilter, setDateFilter] = useState(defaultDateRange);
    const [formValidated, setFormValidated] = useState(false);

    const { setAlert } = useAlert();

    useEffect(() => {
        getBusinessUnits();
        if (businessUnits) {
            getLines();
        }
    }, [selectedBusinessUnits]);

    const exportQirData = async () => {
        setLoadingQirData(true);

        let from = dateFilter?.from;
        let to = dateFilter?.to;

        let url = `api/v1/reporting/qirData?dateFrom=${from}&dateTo=${to}`

        if (selectedBusinessUnits != null){
            url = url + `&businessUnits=${selectedBusinessUnits}`
        }

        if (selectedLines != null){
            url = url + `&lines=${selectedLines}`
        }

        await fetchBlobAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "application/csv");
            })
            .then(blob => {
                setLoadingQirData(false);
                // Does the filename need to be unique?
                downloadFile(blob, `QirData.csv`);
            })
            .catch(error => {
                setLoadingQirData(false);
                setAlert("danger", "Error", error.message);
            });
    }

    const downloadFile = (blob, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click',
            {
                view: window,
                bubbles: true,
                cancelable: true,
            });
        a.dispatchEvent(clickEvt);
        a.remove();
    }

    async function getBusinessUnits() {
        fetchDataAuthenticated("api/v1/businessunit")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get business units");
            })
            .then(data => {
                setBusinessUnits(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    async function getLines() {
        let businessUnitIds = [];

        if (selectedBusinessUnits && selectedBusinessUnits.length > 0) {
            businessUnitIds = businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)).map(bu => bu.businessUnitId);
        } else {
            businessUnitIds = businessUnits.map(bu => bu.businessUnitId);
        }

        fetchDataAuthenticated(`api/v1/line/multiplebusinessunit?businessUnitIds=${businessUnitIds}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Failed to get lines");
            })
            .then(data => {
                setLines(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        setFormValidated(true);

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            // produce report
            exportQirData();
        }
    };

    return (
        <>
            <Form id="reportingQirListForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                <Row className="mb-3 gy-2 d-flex align-items-bottom">
                    <Col md>
                        <DateRange dateFilter={dateFilter} setDateFilter={setDateFilter}/>
                    </Col>
                    <Col md>
                        <MultiselectBox
                            name="Business"
                            options={businessUnits}
                            selectedOptions={businessUnits && selectedBusinessUnits && selectedBusinessUnits.length > 0 ? businessUnits.filter(bu => selectedBusinessUnits.includes(bu.code)) : []}
                            setSelectedOptions={setSelectedBusinessUnits}
                            map="code" />
                    </Col>
                    <Col md>
                        <MultiselectBox
                            name="Lines"
                            options={lines}
                            selectedOptions={lines && selectedLines && selectedLines.length > 0 ? lines.filter(l => selectedLines.includes(l.productionLine)) : []}
                            setSelectedOptions={setSelectedLines}
                            map="productionLine" />
                    </Col>
                    <Col md>
                        <div className="d-flex justify-content-end">
                            <Button variant="primary" type="submit" form="reportingQirListForm" disabled={loadingQirData}>
                                {t("Reporting.ExportQirData")}
                                {loadingQirData &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ms-2"
                                    />
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>

            <ReportingQirListTable tableName="qirs-reporting-table" tableFilters={{ selectedBusinessUnits: selectedBusinessUnits, selectedLines: selectedLines, dateFilter: dateFilter }} />
        </>
    );
}

export default QualityIncidentReportsTable;