const FILTERS_LOTLIST_SESSION_KEY_NAME = "Filters.LotList";

export const persistSortOrder = (persistKey, sortOrder) => {
    // persist to local storage
    sessionStorage.setItem(`SortOrder.${persistKey}`, JSON.stringify(sortOrder));
}

export const getSortOrder = (persistKey) => {
    let sortOrder = sessionStorage.getItem(`SortOrder.${persistKey}`);

    if (sortOrder) {
        return JSON.parse(sortOrder);
    }
}

export const persistLotListFilters = (key, value) => {
    // key = 'includeGraded';
    // value = true or value = false
    
    // get filters for all columns
    let filters = getLotListFilters();

    // First call to this we won't have a value for filters
    if (filters === undefined) {
        filters = {};
    }
    
    // This should always be true or false, but just in case check for undefined
    if (value !== undefined) {
        // Add/update filter values
        if (key === 'includeGraded') {
            filters.includeGraded = value;
        }

        sessionStorage.setItem(FILTERS_LOTLIST_SESSION_KEY_NAME, JSON.stringify(filters));
    }
    else {
        // Remove filter if filter value is undefined
        if (filters) {
            if (key === 'includeGraded') {
                // Our default for this is false, see lot list
                filters.includeGraded = false;
            }

            sessionStorage.setItem(FILTERS_LOTLIST_SESSION_KEY_NAME, JSON.stringify(filters));
        }
    }
}

export const persistTableFilters = (persistKey, filters) => {
    // persist to local storage
    sessionStorage.setItem(`Filters.${persistKey}`, JSON.stringify(filters));
}

export const getTableFilters = (persistKey) => {
    let tableFilters = sessionStorage.getItem(`Filters.${persistKey}`);
    if (tableFilters) {
        return JSON.parse(tableFilters);
    }
}

export const getLotListFilters = () => {
    let filters = sessionStorage.getItem(FILTERS_LOTLIST_SESSION_KEY_NAME);
    if (filters) {
        return JSON.parse(filters);
    }
}