import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { RequiredDialogInput, DialogInput, RequiredDialogSelect, DialogSelectWithMoreConfig, DialogCheckbox, DialogIntegerInput } from '../../DialogInputs';
import FunctionParamsDialog from './FunctionParamsDialog';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { useCalcFunctions, useLabFunctions, useOffspecFunctions } from '../../Utilities/Hooks';

function QualityEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {
    const [fieldErrors, setFieldErrors] = useState({});
    const calcFunctions = useCalcFunctions();
    const labFunctions = useLabFunctions();
    const offspecFunctions = useOffspecFunctions();

    const saveQuality = (quality) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/quality`;
            if (quality.qualityId != null) {
                url += `/${quality.qualityId}`;
            }

            if (quality.defaultDecimalPlaces === "") {
                quality.defaultDecimalPlaces = null;
            }

            if (quality.specificGravity === "") {
                quality.specificGravity = null;
            }

            if (quality.displayOrder === "") {
                quality.displayOrder = null;
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(quality))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("there was a problem!" + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    const FunctionNameOnly = (functionNameWithParams) => {
        if (functionNameWithParams === undefined || functionNameWithParams == null)
            return functionNameWithParams;

        let braceIndex = functionNameWithParams.indexOf("(");
        if (braceIndex === -1)
            return functionNameWithParams;

        return functionNameWithParams.substr(0, braceIndex);
    }

    const GetMatchingFunction = (functions, findFunctionName) => {

        if (functions === undefined)
            return null;

        let findFunctionNameWithoutParams = FunctionNameOnly(findFunctionName);

        for (let idx = 0; idx < functions.length; idx++) {
            let func = functions[idx];
            let funcNameWithoutParams = FunctionNameOnly(func.name);

            if (findFunctionNameWithoutParams === funcNameWithoutParams) {
                return func;
            }
        }

        return null;
    }

    const GetFunctionParameterValues = (functionNameWithParams) => {
        if (functionNameWithParams === undefined || functionNameWithParams == null)
            return [];

        let braceIndex = functionNameWithParams.indexOf("(");
        if (braceIndex === -1)
            return [];

        let paramsPart = functionNameWithParams.substring(braceIndex + 1, functionNameWithParams.length - 1);

        return paramsPart.split(",");
    }

    const FunctionParamsCell = ({ func, fieldName }) => {

        const [showFunctionParams, setShowFunctionParams] = useState(false);
        let initialParameterValues = GetFunctionParameterValues(dialogData[fieldName]);
        const [parameters, setParameters] = useState(initialParameterValues);

        const SaveParams = (params) => {
            // Put the parameters back into a full function name string + args in the dialog data

            // Common dialog save expects a promise.. We can 'reject' here with field errors if we want
            let promise = new Promise((resolve, reject) => {
                // NB, params is an object if its been modified, it it hasn't (user just pressed ok) then its an array..
                let functionNameWithParams = func.name + "(";

                for (var paramIdx = 0; paramIdx < func.parameters.length; paramIdx++) {
                    if (paramIdx > 0)
                        functionNameWithParams += ",";

                    functionNameWithParams += params[paramIdx];
                }
                functionNameWithParams += ")";

                // Anything to validate in here?

                setFieldErrors({
                    ...fieldErrors,
                    [fieldName]: null
                });

                setDialogData({
                    ...dialogData,
                    [fieldName]: functionNameWithParams
                });

                let fieldDOM = document.querySelector("[name=" + fieldName + "]");
                fieldDOM.setCustomValidity("");

                resolve(functionNameWithParams);
            });

            return promise;
        }

        return (
            <>
                <FontAwesomeIcon className="link" icon={faGears} onClick={() => setShowFunctionParams(true)} />
                <FunctionParamsDialog fieldName={fieldName} func={func} showDialog={showFunctionParams} parameters={parameters} setParameters={setParameters} closeDialog={() => setShowFunctionParams(false)} save={SaveParams} />
            </>
        );
    }

    const onChangeFunctionSelect = (functions) => (e) => {
        let field = e.currentTarget.name;

        let value = e.currentTarget.value;
        let matchingFunc = GetMatchingFunction(functions, value);
        let params = GetFunctionParameterValues(value);

        e.currentTarget.setCustomValidity("");

        setDialogData({
            ...dialogData,
            [field]: value
        });

        // Validate selection
        if (matchingFunc.parameters.length !== params.length) {
            e.currentTarget.setCustomValidity("Invalid arguments supplied");
        }

        if (e.currentTarget.validity.valid) {
            setFieldErrors({
                ...fieldErrors,
                [field]: null
            });
        }
        else {
            setFieldErrors({
                ...fieldErrors,
                [field]: e.currentTarget.validationMessage
            });
        }
    }

    const renderFunctionSelect = (controlId, title, name, functions) => {
        let matchingFunc = GetMatchingFunction(functions, dialogData[name]);
        let selectedFnhasArgs = (matchingFunc != null && matchingFunc.parameters.length > 0);

        let functionConfig = selectedFnhasArgs ? <FunctionParamsCell func={matchingFunc} fieldName={name}></FunctionParamsCell> : <></>;

        return (
            <>
                <DialogSelectWithMoreConfig controlId={controlId} title={title} name={name} additionalConfig={functionConfig} value={FunctionNameOnly(dialogData[name])} error={fieldErrors[name]} onChange={onChangeFunctionSelect(functions)}>
                    <option value="" key={0} >Please Select...</option>
                    {functions.map((func, index) => <option value={func.name} key={index}>{(matchingFunc !== null) && (matchingFunc.name === func.name) ? dialogData[name] : func.name}</option>)}
                </DialogSelectWithMoreConfig>
            </>
        );
    }
    
    return (
        <CommonConfigDialog title={"Quality - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveQuality} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} wideDialog={true}>
            <RequiredDialogInput controlId="formQualityName" title="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formQualityDescription" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} required={false}/>
            <DialogInput controlId="formQualityType" title="Type" name="type" value={dialogData.type} error={fieldErrors.type} onChange={onChange} required={false} />
            <DialogInput controlId="formQualityUnits" title="Units" name="units" value={dialogData.units} error={fieldErrors.units} onChange={onChange}/>
            <DialogCheckbox controlId="formQualityIsNumeric" title="Numeric?" name="isNumeric" checked={dialogData.isNumeric} error={fieldErrors.isNumeric} onChange={onChange}/>
            <DialogIntegerInput controlId="formQualityDefaultDPs" title="Default DPs" name="defaultDecimalPlaces" value={dialogData.defaultDecimalPlaces} error={fieldErrors.defaultDecimalPlaces} onChange={onChange}/>
            <RequiredDialogInput controlId="formQualityDefaultValue" title="Default Value To Use" name="defaultValueToUse" value={dialogData.defaultValueToUse} error={fieldErrors.defaultValueToUse} onChange={onChange} />
            <DialogInput controlId="formQualityAnalysis" title="Analysis" name="analysis" value={dialogData.analysis} error={fieldErrors.analysis} onChange={onChange} />
            <DialogInput controlId="formQualityComponent" title="Component" name="component" value={dialogData.component} error={fieldErrors.component} onChange={onChange} />
            <DialogInput controlId="formQualityReleaseAnalysis" title="Release Analysis" name="releaseAnalysis" value={dialogData.releaseAnalysis} error={fieldErrors.releaseAnalysis} onChange={onChange} />
            <DialogInput controlId="formQualityReleaseComponent" title="Release Component" name="releaseComponent" value={dialogData.releaseComponent} error={fieldErrors.releaseComponent} onChange={onChange} />
            <DialogIntegerInput controlId="formQualityDisplayOrder" title="Display Order" name="displayOrder" value={dialogData.displayOrder} error={fieldErrors.displayOrder} onChange={onChange} />
            <DialogInput controlId="formQualityMin" title="Min Value" name="masterMinValue" value={dialogData.masterMinValue} error={fieldErrors.masterMinValue} onChange={onChange} />
            <DialogInput controlId="formQualityMax" title="Max Value" name="masterMaxValue" value={dialogData.masterMaxValue} error={fieldErrors.masterMaxValue} onChange={onChange} />
            <DialogInput controlId="formQualitySpecificGravity" title="Specific Gravity" name="specificGravity" value={dialogData.specificGravity} error={fieldErrors.specificGravity} onChange={onChange} />
            { renderFunctionSelect("formQualityTransferCalc", "Transfer Calc Func", "transferCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityTransferOffspec", "Transfer Offspec Func", "transferIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityBatchCalc", "Batch Calc Func", "batchCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityBatchLabCalc", "Batch Lab Calc Func", "batchLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityBatchOffspec", "Batch Offspec Func", "batchIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityLotCalc", "Lot Calc Func", "lotCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityLotLabCalc", "Lot Lab Calc Func", "lotLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityLotOffspec", "Lot Offspec Func", "lotIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityPackoutLotCalc", "PO Lot Calc Func", "packoutLotCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityPackoutLotLabCalc", "PO Lot Lab Calc Func", "packoutLotLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityPackoutLotOffspec", "PO Lot Offspec Func", "packoutLotIsOffspecFunction", offspecFunctions)}
            <RequiredDialogSelect controlId="formQualityBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <DialogCheckbox disabled={dialogData.qualityId === null} controlId="formQualityIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange}/>
        </CommonConfigDialog>
    );
}

export default QualityEditDialog;