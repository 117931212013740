import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

import { useTranslationUtils } from '../Utilities/TranslationUtils';

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {

    const { tp } = useTranslationUtils();

    //    const placeholder = preFilteredRows ? `Search ${preFilteredRows.length} records...` : "Search...";
    const placeholder = preFilteredRows ? tp("Search.PlaceHolderWithCount", { length: preFilteredRows.length }) : tp("Search.PlaceHolder");

    const [value, setValue] = useState(filterValue);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 200);

    return (
        <input className="form-control form-control-sm"
            value={value || ''}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={placeholder}
        />
    )
}

export default DefaultColumnFilter;